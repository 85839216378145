import { trpc } from "@/utils/trpc";
import { useQuery } from "@tanstack/react-query";
import { PageOptions } from "@yoga-app/db-utils";
import { useEffect, useMemo, useState } from "react";
import { debounce } from "../utils/postHelpers";
import { useAuth } from "./auth";

export const useCreateUserMutation = () => {
  const utils = trpc.useUtils();

  return trpc.user.createUser.useMutation({
    onSuccess: async () => {
      await utils.user.getAllUsers.invalidate();
    },
  });
};

export const useGetUserQuery = () => {
  const utils = trpc.useUtils();

  return (userId: string) =>
    utils.user.getUser.fetch({ userId }, { staleTime: 60 * 60 * 1000, cacheTime: 60 * 60 * 1000 });
};

export const useGetUser = (userId: string) => {
  return trpc.user.getUser.useQuery(
    { userId: userId },
    {
      enabled: !!userId,
      staleTime: 60 * 60 * 1000, // 60 minutes
      cacheTime: 60 * 60 * 1000,
    },
  );
};

export const useGetUserByUsername = (username: string) => {
  return trpc.user.getUserByUsername.useQuery(
    { username },
    {
      enabled: !!username,
    },
  );
};

export const useGetUserProfile = (username: string) => {
  return trpc.user.getUserProfile.useQuery(
    { username },
    {
      enabled: !!username,
    },
  );
};

export const useGetAllUsers = () => {
  const utils = trpc.useUtils();
  return useQuery(["allUsers"], () => utils.client.user.getAllUsers.query({ fields: ["id", "username"] }));
};

export const useUpdateUserMutation = () => {
  const utils = trpc.useUtils();

  return trpc.user.updateUser.useMutation({
    onSuccess: async (_, input) => {
      await utils.user.getUser.invalidate({ userId: input.id });
    },
  });
};

export const useFollowUserMutation = () => {
  const utils = trpc.useUtils();

  return trpc.user.followUser.useMutation({
    onSuccess: async (_, input) => {
      await Promise.all([
        utils.user.getUser.invalidate({ userId: input.userId }),
        utils.user.getUser.invalidate({ userId: input.followingUserId }),
        utils.user.checkFollowStatus.invalidate({ followingUserId: input.followingUserId }),
        utils.user.checkFollowStatus.invalidate({ followingUserId: input.userId }),
      ]);
    },
  });
};

export const useUnfollowUserMutation = () => {
  const utils = trpc.useUtils();

  return trpc.user.unfollowUser.useMutation({
    onSuccess: async (_, input) => {
      await Promise.all([
        utils.user.getUser.invalidate({ userId: input.userId }),
        utils.user.getUser.invalidate({ userId: input.followingUserId }),
        utils.user.checkFollowStatus.invalidate({ followingUserId: input.followingUserId }),
        utils.user.checkFollowStatus.invalidate({ followingUserId: input.userId }),
      ]);
    },
  });
};

interface UseFollowProps {
  userId: string;
  followingUserId: string;
}

export const useFollow = ({ userId, followingUserId }: UseFollowProps) => {
  const { isAuthenticated } = useAuth();
  const followUserMutation = useFollowUserMutation();
  const unfollowUserMutation = useUnfollowUserMutation();
  const [loading, setLoading] = useState(false);
  const { data, isLoading: isCheckLoading } = useCheckFollowStatus(followingUserId, isAuthenticated);

  const [following, setFollowing] = useState<boolean | undefined>();

  useEffect(() => {
    if (data) {
      setFollowing(data.isFollowing);
    }
  }, [data]);

  // Create a stable debounced function
  const handleFollowClick = useMemo(
    () =>
      debounce(async () => {
        if (following === undefined) return;
        setLoading(true);
        try {
          if (following) {
            await unfollowUserMutation.mutateAsync({ userId, followingUserId });
          } else {
            await followUserMutation.mutateAsync({ userId, followingUserId });
          }
          setFollowing((prev) => !prev);
        } catch (error) {
          console.error("Failed to toggle follow:", error);
        } finally {
          setLoading(false);
        }
      }, 300),
    [following, followUserMutation, unfollowUserMutation, userId, followingUserId],
  );

  return {
    following,
    isLoading: isCheckLoading || loading,
    handleFollowClick,
  };
};

export const useCheckFollowStatus = (followingUserId: string, isAuthenticated?: boolean) => {
  return trpc.user.checkFollowStatus.useQuery(
    { followingUserId },
    {
      enabled: !!followingUserId && isAuthenticated !== false,
    },
  );
};

export const useGetUserFollowFeed = (pageOptions: PageOptions) => {
  return trpc.user.getUserFollowFeed.useInfiniteQuery(
    {
      limit: pageOptions.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 300000, // 5 minutes
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  );
};

export const useSearchUsernameByPrefix = (usernamePrefix: string, pageOptions: PageOptions) => {
  return trpc.user.searchUsernameByPrefix.useInfiniteQuery(
    {
      usernamePrefix,
      limit: pageOptions.limit,
    },
    {
      enabled: !!usernamePrefix && usernamePrefix.length > 1,
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 300000, // 5 minutes
    },
  );
};
