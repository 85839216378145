import { z } from "zod";
import { contentSchema } from "./shared.js";

export const locationUrlSchema = z.enum(["online", "in-person"], {
  errorMap: () => ({ message: "Location type is required" }),
});
export type LocationUrl = z.infer<typeof locationUrlSchema>;

export const daysOfWeekSchema = z.array(
  z.enum(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]),
);
export type DaysOfWeek = z.infer<typeof daysOfWeekSchema>;

export const repeatConfigSchema = z.object({
  repeatEvery: z.object({
    interval: z.number().min(1, { message: "Interval must be at least 1" }),
    unit: z.enum(["day", "week", "month"], { message: "Invalid unit" }),
  }),
  repeatOn: daysOfWeekSchema.optional(),
  endDateTime: z.string().datetime({ offset: true, message: "An end date is required for repeating events" }),
});

export type RepeatConfig = z.infer<typeof repeatConfigSchema>;

/**
 * A session can be thought of as a class,session, event, workshop, webinar. Its a specific activity designed for participation
 */
export const sessionSchema = contentSchema.extend({
  /**
   * The title of the session. Used as a short identifier of a session.
   */
  title: z
    .string({
      required_error: "Title is required",
      invalid_type_error: "Title must be a string",
    })
    .min(1, { message: "Title is required" })
    .max(20, { message: "Title must be 20 characters or less" }),
  /**
   * Duration of the session in minutes
   */
  duration: z.number({
    required_error: "Duration is required",
    invalid_type_error: "Duration must be a number",
  }),
  /**
   * Start Date and time of the session
   */
  startDateTime: z.string().datetime({ offset: true, message: "Invalid start date and time format" }),
  /**
   * End Date and time of the session
   */
  endDateTime: z.string().datetime({ offset: true, message: "Invalid end date and time format" }),

  /**
   * Whether the session is repeating or not
   */
  isRepeating: z.boolean().default(false),

  /**
   * Configuration for repeating sessions
   */
  repeatConfig: repeatConfigSchema.optional(),

  /**
   * Number of participants in the session
   */
  participantCount: z.number(),

  /**
   * Maximum number of participants allowed
   */
  maxParticipantCount: z
    .number({
      required_error: "Max Participants is required",
      invalid_type_error: "Max Participants must be a number",
    })
    .optional(),
  /**
   * Location type for the session
   */
  locationType: locationUrlSchema,
  /**
   * Location URL for online sessions
   */
  locationUrl: z
    .string()
    .regex(/[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, {
      message: "Invalid URL format",
    })
    .optional()
    .or(z.literal("")),
  /**
   * Address line 1 for in-person sessions
   */
  addressLine1: z.string().optional(),
  /**
   * Address line 2 for in-person sessions
   */
  addressLine2: z.string().optional(),
  /**
   * City for in-person sessions
   */
  city: z.string().optional(),
  /**
   * State for in-person sessions
   */
  state: z.string().optional(),
  /**
   * Postal code for in-person sessions
   */
  postalCode: z.string().optional(),
  /**
   * Country for in-person sessions
   */
  country: z.string().optional(),

  /**
   * Notes for the session, given to the user after they've purchased the session
   */
  notes: z.string().max(500).optional(),
});

export type Session = z.infer<typeof sessionSchema>;

export const sessionEventSchema = z.object({
  /**
   * Random uuid for the session event.
   */
  id: z.string().min(1),
  /**
   * The unique identifier for the session.
   */
  sessionId: z.string().min(1),
  /**
   * The user id of the user who created the session.
   */
  sessionOwnerUserId: z.string().min(1),
  /**
   * The username of the user who created the session.
   */
  sessionOwnerUsername: z.string().min(1),
  /**
   * The date and time the session event starts.
   */
  startDateTime: z.string().datetime({ offset: true }),
  /**
   * The date and time the session event ends.
   */
  endDateTime: z.string().datetime({ offset: true }),
  /**
   * Duration of the session in minutes
   */
  duration: z.number({
    required_error: "Duration is required",
    invalid_type_error: "Duration must be a number",
  }),

  /**
   * price of the content, in the smallest currency unit e.g., 100p for £1
   */
  price: z.union([
    z.literal(0), // Allow a price of 0 for free content
    z
      .number({
        required_error: "Price is required",
        invalid_type_error: "Price must be a number",
      })
      //TODO: Is this a valid min amount?
      .min(230, { message: "The minimum price for paid content is £2.30" }), // Enforce minimum of 230 for paid content
  ]),
  /**
   * if the session event is apart of a repeating schedule
   */
  isRepeatingEvent: z.boolean(),
  /**
   * The title of the session event.
   */
  title: z.string().min(1),
  /**
   * The location, either online or in-person.
   */
  locationType: locationUrlSchema,

  /**
   * Number of participants in the session
   */
  participantCount: z.number(),

  /**
   * Maximum number of participants allowed
   */
  maxParticipantCount: z
    .number({
      required_error: "Max Participants is required",
      invalid_type_error: "Max Participants must be a number",
    })
    .optional(),
  /**
   * The date and time the content was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),
  /**
   * The date and time the content was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});

export type SessionEvent = z.infer<typeof sessionEventSchema>;

/**
 * sessionEventUser is the relationship between a user and a session event. When a user purchases / attends a session, a user session event record is created
 */
export const sessionEventUserSchema = z.object({
  /**
   * The user id of the user who is participating in the session.
   */
  userId: z.string(),
  /**
   * The session id of the session.
   */
  sessionId: z.string(),
  /**
   * The session event id of the session event.
   */
  sessionEventId: z.string(),

  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type SessionEventUser = z.infer<typeof sessionEventUserSchema>;
