import Icon from "../ui/Icon";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  className?: string;
}

const Modal = ({ isOpen, onClose, children, title, className }: ModalProps) => {
  if (!isOpen) return null;

  return (
    <dialog id="modal" className={`modal modal-bottom z-[100] md:modal-middle ${isOpen ? "modal-open" : ""}`}>
      <div className={`modal-box relative max-h-[75%] md:max-w-screen-lg flex flex-col bg-neutral pb-0 ${className}`}>
        <div className="sticky top-0 bg-neutral z-50">
          <button
            className="btn btn-sm btn-circle absolute right-0.5 top-0.5 z-50 border-0 bg-neutral hover:border hover:border-secondary"
            onClick={onClose}
          >
            <Icon name="close" />
          </button>
          <h2 className="text-2xl font-semibold tracking-wide text-gray-800">{title}</h2>
        </div>
        <div className="overflow-y-auto no-scrollbar py-4">{children}</div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={onClose} className="cursor-default">
          close
        </button>
      </form>
    </dialog>
  );
};

export default Modal;
