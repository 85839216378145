import { Entity, isImageEntity } from "@yoga-app/types";

export const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64File = reader.result?.toString().split(",")[1];
      if (!base64File) {
        reject(new Error("Failed to convert file to base64"));
      } else {
        resolve(base64File);
      }
    };
    reader.onerror = reject;
  });
};

export const getImageEntityKeys = (entities: Entity[]): string[] => {
  return entities
    .filter(isImageEntity)
    .map((entity) => {
      return entity.url?.[800] || entity.url?.[400] || Object.values(entity.url || {})[0];
    })
    .filter(Boolean);
};
