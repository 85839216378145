import { useAuth } from "@/hooks/auth";
import { useGetSessionEventUsersBySessionEventId } from "@/hooks/session";
import { useGetUser } from "@/src/hooks/user";
import { SessionEvent, SessionEventUser } from "@yoga-app/types";
import { Link, useNavigate } from "react-router-dom";
import ProfilePicture from "../profile/ProfilePicture";
import Icon from "../ui/Icon";
import InfiniteList from "../ui/InfiniteList";
import Modal from "../ui/Modal";

interface ParticipantsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedSessionEvent?: SessionEvent;
}

const ParticipantsModal = ({ isOpen, onClose, selectedSessionEvent }: ParticipantsModalProps) => {
  const { data, fetchNextPage, hasNextPage, isFetching } = useGetSessionEventUsersBySessionEventId(
    selectedSessionEvent?.id ?? "",
    selectedSessionEvent?.sessionOwnerUserId ?? "",
    {
      limit: 10,
    },
  );

  const items = data?.pages.flatMap((page) => page.items.map((item) => ({ id: item.userId, ...item }))) || [];

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="" className="md:w-96 md:max-h-96 min-h-96">
      <div className="w-full h-full mt-6">
        {items.length === 0 && !isFetching ? (
          <div className="flex items-center justify-center h-full text-center text-gray-500">No participants yet.</div>
        ) : (
          <InfiniteList
            data={items}
            fetchNextPage={async () => {
              await fetchNextPage();
            }}
            hasNextPage={!!hasNextPage}
            isFetching={isFetching}
            CardComponent={ParticipantCard}
            className="divide-y divide-neutral-200"
          />
        )}
      </div>
    </Modal>
  );
};

export default ParticipantsModal;

interface ParticipantCardProps {
  item: SessionEventUser;
}

const ParticipantCard = ({ item }: ParticipantCardProps) => {
  const { getUserId } = useAuth();
  const currentUserId = getUserId() as string;
  const { data: user } = useGetUser(item.userId);
  const navigate = useNavigate();

  if (!user) return <></>;

  return (
    <div className="flex items-center justify-between p-3 bg-base-100 rounded-xl shadow">
      <Link to={`/${user.username}`}>
        <div className="flex items-center space-x-2">
          <ProfilePicture
            key={user.id}
            src={user.profilePic?.[150]}
            user={user}
            styles="w-10 h-10 cursor-pointer"
            avatarSize="text-sm"
          />
          <div className="flex flex-col">
            <span className="font-bold text-secondary overflow-ellipsis">{user.displayName ?? user.username}</span>
            <span className="text-sm text-gray-500">@{user.username}</span>
          </div>
        </div>
      </Link>

      {user.id !== currentUserId && (
        <button
          className="btn btn-outline text-primary border-primary rounded-md px-4 min-h-10 h-10 flex items-center gap-1 sm:rounded-lg sm:gap-2"
          onClick={() => navigate(`/messages?userId=${user.id}`)}
        >
          <Icon name="mail" size={20} color="currentColor" />
        </button>
      )}
    </div>
  );
};
