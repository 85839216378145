import { z } from "zod";
import { entitySchema } from "./shared.js";

export const chatSchema = z.object({
  id: z.string(),
  users: z.array(z.string()),
  readStatus: z.boolean(),

  /**
   * The date and time the content was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),
  /**
   * The date and time the content was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});
export type Chat = z.infer<typeof chatSchema>;

export const messageSchema = z.object({
  id: z.string(),
  chatId: z.string(),
  senderUserId: z.string(),
  text: z.string().max(5000, { message: "Message is too long" }),
  /**
   * Entities such as images, videos
   */
  entities: entitySchema.array().optional(),
  readStatus: z.boolean(),

  /**
   * Mentions. Tag users in a post, linking them to the post.
   * array of user ids
   */
  mentions: z.array(z.string()).optional(),

  /**
   * The date and time the content was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),
  /**
   * The date and time the content was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});
export type Message = z.infer<typeof messageSchema>;
