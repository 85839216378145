import React, { forwardRef } from "react";

interface ImageInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageInput = forwardRef<HTMLInputElement, ImageInputProps>(({ onChange, ...props }, ref) => {
  return (
    <input
      type="file"
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="hidden"
      accept="image/*,image/heic,image/heif"
      onChange={onChange}
      ref={ref}
      {...props}
    />
  );
});

ImageInput.displayName = "ImageInput";

export default ImageInput;
