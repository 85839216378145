import { z } from "zod";
import { imageSizesSchema } from "./shared.js";

export const userSchema = z.object({
  /**
   * A unique identifier for the user.
   *
   * Cognito Auth Sub
   */
  id: z.string(),

  /**
   * The user's username.
   */
  username: z
    .string()
    .min(4, "Username must be at least 4 characters")
    .max(15, "Username must be at most 15 characters")
    .regex(/^[a-zA-Z0-9_]+$/, "Username can only contain letters, numbers, and underscores. No spaces allowed."),

  /**
   * The user's display name.
   */
  displayName: z
    .string()
    .max(50, "Display name must not exceed 50 characters")
    .regex(/^[a-zA-Z0-9_\- ]*$/, "Display name can only contain letters, numbers, underscores, hyphens, and spaces")
    .optional(),

  /**
   * The user's email address.
   */
  email: z.string().email(),

  /**
   * The user's bio.
   */
  bio: z.string().optional(),

  /**
   * The user's profile picture with sizes.
   *
   * e.g., { "150": "/user-uploads/user123/profile-150.webp", "400": "/user-uploads/user123/profile-400.webp" }
   */
  profilePic: imageSizesSchema.optional(),

  /**
   * The user's cover picture.
   *
   * e.g., { "1500x500": "/user-uploads/user123/profileCover-1500x500.webp", }
   */
  profileCoverPic: imageSizesSchema.optional(),

  /**
   * The users stripe connected account id. When creating a stripe connected account, you're registering
   * a user with Stripe so they can receive payments.
   * We'll get this when a user wants to start receiving payments.
   */
  stripeAccountId: z.string().optional(),

  /**
   * Admin status.
   *
   * Manage content, ect.
   */
  admin: z.boolean().optional(),

  /**
   * The user's onboarding status.
   *
   * Marked as complete when the user has completed the onboarding process on first sign in.
   */
  onboardingComplete: z.boolean().optional(),

  /**
   * The date and time the user was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),

  /**
   * The date and time the user was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});

export type User = z.infer<typeof userSchema>;

export const usernameSearchSchema = z.object({
  id: z.string(),
  username: z.string(),
  /**
   * The date and time the user was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),

  /**
   * The date and time the user was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});
export type UsernameSearch = z.infer<typeof usernameSearchSchema>;

export const userFollowCountSchema = z.object({
  /**
   * A unique identifier for the user.
   * Matches the id in the userSchema.
   */
  userId: z.string(),

  /**
   * Count of followers.
   */
  followerCount: z.number().default(0),

  /**
   * Count of followings.
   */
  followingCount: z.number().default(0),

  /**
   * The date and time the record was created.
   */
  createdAt: z.string().datetime({ offset: true }),

  /**
   * The date and time the record was last updated.
   */
  updatedAt: z.string().datetime({ offset: true }),
});

export type UserFollowCount = z.infer<typeof userFollowCountSchema>;

export const followSchema = z.object({
  /**
   * The user id of the user who is following.
   */
  followerUserId: z.string(),

  /**
   * The user id of the user being followed.
   */
  followingUserId: z.string(),

  /**
   * The date and time the post was created.
   *
   * The date is represented as an ISO 8601 date string.
   */
  createdAt: z.string().datetime({ offset: true }),

  /**
   * The date and time the post was last updated.
   *
   * The date is represented as an ISO 8601 date string.
   */
  updatedAt: z.string().datetime({ offset: true }),
});

export type Follow = z.infer<typeof followSchema>;
