import { z } from "zod";
import { contentItemUri } from "./shared.js";

export const userFollowFeedSchema = z.object({
  /**
   * The ID of the user whose feed this content belongs to.
   */
  userId: z.string(),

  /**
   * The ID of the user who owns the content.
   */
  contentOwnerUserId: z.string(),

  /**
   * A unique URI or identifier for the content item.
   * This could be a string like "CONTENT#<contentId>".
   */
  contentItemUri: contentItemUri,

  /**
   * The timestamp when the content was originally created.
   */
  contentCreatedAt: z.string().datetime({ offset: true }),

  /**
   * The timestamp when this feed entry was created (fan-out process).
   */
  createdAt: z.string().datetime({ offset: true }),

  /**
   * The timestamp when this feed entry was last updated.
   */
  updatedAt: z.string().datetime({ offset: true }),
});

export type UserFollowFeed = z.infer<typeof userFollowFeedSchema>;
