import { PageOptions } from "@yoga-app/db-utils";
import { ContentItemUri } from "@yoga-app/types";
import { trpc } from "../utils/trpc";

export const useGetContentItem = (contentItemUri: ContentItemUri, fieldsToInclude?: string[]) => {
  return trpc.content.getContentItem.useQuery(
    {
      contentItemUri: contentItemUri,
      fieldsToInclude,
    },
    {
      enabled: !!contentItemUri,
      staleTime: 600000, // 10 minutes
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );
};

export const useGetContent = (userId: string, pageOptions: PageOptions) => {
  return trpc.content.getContentByUser.useInfiniteQuery(
    {
      userId: userId,
      limit: pageOptions.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 300000, // 5 minutes
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  );
};

export const useReportContent = () => {
  return trpc.content.reportContent.useMutation();
};

export const useGetReportedContentByUser = (pageOptions: PageOptions) => {
  return trpc.content.getReportedContentByUser.useInfiniteQuery(
    {
      limit: pageOptions.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 300000, // 5 minutes
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  );
};

export const useGetAllReportedContent = (pageOptions: PageOptions) => {
  return trpc.content.getAllReportedContent.useInfiniteQuery(
    {
      limit: pageOptions.limit,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 300000, // 5 minutes
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  );
};

export const useApproveReportedContent = () => {
  const utils = trpc.useUtils();

  return trpc.content.approveReportedContent.useMutation({
    onSuccess: () => {
      void utils.content.getAllReportedContent.invalidate();
    },
  });
};
