const config = {
  app: {
    region: import.meta.env.VITE_APP_REGION,
    stage: import.meta.env.VITE_APP_STAGE,
    url: import.meta.env.VITE_APP_URL,
  },
  api: {
    url: import.meta.env.VITE_APP_API_URL || window.location.origin,
  },
  auth: {
    userPoolClientId: import.meta.env.VITE_APP_USER_POOL_CLIENT_ID,
  },
  stripe: {
    publishableKey: import.meta.env.VITE_APP_STRIPE_PUBLISHABLE_KEY,
  },
};

export default config;
