import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { Suspense, lazy, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import GlobalErrorBoundary from "./components/GlobalErrorBoundary";
import PageNotFound from "./components/PageNotFound";
import ProtectedAdminRoute from "./components/admin/ProtectedAdminRoute";
import AdminDashboardLayout from "./components/admin/layout/AdminDashboardLayout";
import ProtectedRoute from "./components/layout/ProtectedRoute";
import RootLayout from "./components/layout/RootLayout";
import Loading from "./components/ui/Loading";
import { useAuth } from "./hooks/auth";
import Home from "./pages/Home";
import ReportedContent from "./pages/admin/ReportedContent";
import CookiePolicy from "./pages/support/legal/CookiePolicy";
import PrivacyPolicy from "./pages/support/legal/PrivacyPolicy";
import TermsOfService from "./pages/support/legal/TermsOfService";
import { trpc, useTrpcClient } from "./utils/trpc";

// Lazy load the page components
const Profile = lazy(() => import("./pages/profile/Profile"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const Signin = lazy(() => import("./pages/auth/Signin"));
const Signup = lazy(() => import("./pages/auth/Signup"));
const ConfirmSignup = lazy(() => import("./pages/auth/ConfirmSignup"));
const Post = lazy(() => import("./pages/content/Post"));
const Package = lazy(() => import("./pages/content/Package"));
const Session = lazy(() => import("./pages/content/Session"));
const EditProfile = lazy(() => import("./pages/profile/EditProfile"));
const Community = lazy(() => import("./pages/Community"));
const Messages = lazy(() => import("./pages/Messages"));
const Notifications = lazy(() => import("./pages/Notifications"));
const Calendar = lazy(() => import("./pages/Calendar"));
const CreateContent = lazy(() => import("./pages/content/CreateContent"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <GlobalErrorBoundary />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "edit-profile",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: "create-content",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <CreateContent />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: "calendar",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <Calendar />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: "messages",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <Messages />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: "notifications",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: "sign-in",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Signin />
          </Suspense>
        ),
      },
      {
        path: "sign-up",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Signup />
          </Suspense>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ForgotPassword />
          </Suspense>
        ),
      },
      {
        path: "reset-password",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ResetPassword />
          </Suspense>
        ),
      },
      {
        path: "confirm-signup",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ConfirmSignup />
          </Suspense>
        ),
      },
      {
        path: "admin",
        element: (
          <ProtectedAdminRoute>
            <Suspense fallback={<Loading className="h-screen" />}>
              <AdminDashboardLayout />
            </Suspense>
          </ProtectedAdminRoute>
        ),
        children: [
          {
            path: "reported-content",
            element: (
              <Suspense fallback={<Loading className="h-screen" />}>
                <ProtectedRoute>
                  <ReportedContent />
                </ProtectedRoute>
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "terms-of-service",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <TermsOfService />
          </Suspense>
        ),
      },
      {
        path: "privacy-policy",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <PrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: "cookie-policy",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <CookiePolicy />
          </Suspense>
        ),
      },
      {
        path: "community/:hashtag",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <ProtectedRoute>
              <Community />
            </ProtectedRoute>
          </Suspense>
        ),
      },
      {
        path: ":username/post/:postId",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Post />
          </Suspense>
        ),
      },
      {
        path: ":username/package/:packageId",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Package />
          </Suspense>
        ),
      },
      {
        path: ":username/session/:sessionId",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Session />
          </Suspense>
        ),
      },
      {
        path: ":username",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <Profile />
          </Suspense>
        ),
      },
      {
        path: "*",
        element: (
          <Suspense fallback={<Loading className="h-screen" />}>
            <PageNotFound />
          </Suspense>
        ),
      },
    ],
  },
]);

export function App() {
  const { refreshSession, getRefreshToken, signOut } = useAuth();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: (failureCount, error: unknown) => {
              if (
                (error as Error)?.message?.toLowerCase()?.includes("jwt expired") ||
                (error as Error)?.message?.toLowerCase()?.includes("jwt verification failed") ||
                (error as Error)?.message?.toLowerCase()?.includes("unable to transform response")
              ) {
                // Do not retry for JWT-related errors
                return false;
              }
              // Allow retries for other errors, but limit to a maximum count
              return failureCount < 3; // Retry up to 3 times for other errors
            },
            onError: async (error: unknown) => {
              if (
                (error as Error)?.message?.toLowerCase()?.includes("unable to transform response") ||
                (error as Error)?.message?.toLowerCase()?.includes("jwt expired") ||
                (error as Error)?.message?.toLowerCase()?.includes("jwt verification failed")
              ) {
                try {
                  const refreshToken = getRefreshToken();

                  if (!refreshToken) {
                    signOut();
                    return;
                  }
                  await refreshSession(refreshToken);
                  await queryClient.refetchQueries();
                } catch (e) {
                  console.error("Session refresh failed", e);
                  signOut();
                }
              }
            },
          },
        },
      }),
  );

  const trpcClient = useTrpcClient();

  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  });

  void persistQueryClient({
    queryClient,
    persister,
    maxAge: Infinity,
  });

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </trpc.Provider>
  );
}
