import { useFileUpload } from "@/hooks/media";
import { useUpdateUserMutation } from "@/hooks/user";
import { FileObject } from "@/src/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { User } from "@yoga-app/types";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import ProfileCoverPicture from "../../profile/ProfileCoverPicture";
import ProfilePicture from "../../profile/ProfilePicture";
import Modal from "../Modal";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const schema = z.object({
  displayName: z
    .string()
    .trim()
    .max(50, "Display name must not exceed 50 characters")
    .regex(/^[a-zA-Z0-9_\- ]*$/, "Display name can only contain letters, numbers, underscores, hyphens, and spaces"),
  profilePic: z.string().optional(),
  profileCoverPic: z.string().optional(),
});

type OnboardingFormValues = z.infer<typeof schema>;

interface OnboardingModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
}

const OnboardingModal = ({ isOpen, onClose, user }: OnboardingModalProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [profilePicPreviewSrc, setProfilePicPreviewSrc] = useState<string | undefined>(user?.profilePic?.["400"]);
  const [profileCoverPicPreviewSrc, setProfileCoverPicPreviewSrc] = useState<string | undefined>(
    user?.profileCoverPic?.["1500x500"],
  );

  const [submitError, setSubmitError] = useState<string | null>(null);

  const { uploadFiles, isUploading: isFileUploading } = useFileUpload();
  const { mutateAsync: updateUser } = useUpdateUserMutation();

  const methods = useForm<OnboardingFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      displayName: user?.displayName,
      profilePic: user?.profilePic?.["400"],
      profileCoverPic: user?.profileCoverPic?.["1500x500"],
    },
  });

  const { handleSubmit, getValues } = methods;

  const handleCoverPhotoChange = async (files: FileObject[]) => {
    setSubmitError(null);

    if (files.length === 0) return;

    const coverFile = files.find((file) => file.imageType === "profileCover");
    if (!coverFile) {
      setSubmitError("No valid cover photo file provided");
      return;
    }
    if (!coverFile.file.type.startsWith("image/")) {
      setSubmitError("Only image files are allowed");
      return;
    }
    if (coverFile.file.size > MAX_FILE_SIZE) {
      setSubmitError("File size exceeds 10MB");
      return;
    }

    try {
      const [uploadedFile] = await uploadFiles([coverFile]);
      if (uploadedFile?.url?.["1500x500"]) {
        await updateUser({
          id: user.id,
          profileCoverPic: uploadedFile.url,
        });

        setProfileCoverPicPreviewSrc(uploadedFile.url["1500x500"]);
      } else {
        throw new Error("Uploaded cover photo does not have the expected size");
      }
    } catch (error) {
      setSubmitError("Failed to update profile cover photo");
      console.error(error);
    }
  };

  const handleProfilePicChange = async (files: FileObject[]) => {
    setSubmitError(null);

    if (files.length === 0) return;

    const profileFile = files.find((file) => file.imageType === "profile");
    if (!profileFile) {
      setSubmitError("No valid profile picture file provided");
      return;
    }
    if (!profileFile.file.type.startsWith("image/")) {
      setSubmitError("Only image files are allowed");
      return;
    }
    if (profileFile.file.size > MAX_FILE_SIZE) {
      setSubmitError("File size exceeds 10MB");
      return;
    }

    try {
      const [uploadedFile] = await uploadFiles([profileFile]);
      if (uploadedFile?.url?.["400"]) {
        await updateUser({
          id: user.id,
          profilePic: uploadedFile.url,
        });

        setProfilePicPreviewSrc(uploadedFile.url["400"]);
      } else {
        throw new Error("Uploaded profile picture does not have the expected size");
      }
    } catch (error) {
      setSubmitError("Failed to update profile picture");
      console.error(error);
    }
  };

  const pages = [
    {
      title: "Profile Picture",
      description: "Add a profile picture to personalize your account.",
      content: (
        <ProfilePicture
          src={profilePicPreviewSrc}
          user={user}
          editable={true}
          onChange={handleProfilePicChange}
          styles="w-48 h-48"
          isUploading={isFileUploading}
        />
      ),
    },
    {
      title: "Cover Photo",
      description: "Upload a cover photo to make your profile stand out.",
      content: (
        <ProfileCoverPicture
          src={profileCoverPicPreviewSrc}
          editable={true}
          onChange={handleCoverPhotoChange}
          isUploading={isFileUploading}
        />
      ),
    },
    {
      title: "Display Name",
      description: "Choose a display name that represents you.",
      content: (
        <input type="text" className="input input-bordered w-full mx-auto" {...methods.register("displayName")} />
      ),
    },
    {
      title: "How to Get Started?",
      description: "Explore the platform, connect with others, create content, and start earning money.",
      content: (
        <div className="text-base space-y-8">
          <ul className="list-disc list-inside space-y-3">
            <li>
              <strong>Create Content:</strong> Share your expertise by creating engaging posts.
            </li>
            <li>
              <strong>Get Verified:</strong> Navigate to the "Monetization" page in your account settings to get
              verified. Once verified, you can start earning money for your sessions (e.g., fitness classes).
            </li>
            <li>
              <strong>Connect with Others:</strong> Follow other users, participate in discussions, and grow your
              community.
            </li>
          </ul>
          <p className="text-base text-secondary">
            For any assistance, feel free to reach out to us at{" "}
            <a href="mailto:support@bodhiga.com" className="text-primary font-bold">
              support@bodhiga.com
            </a>
            .
          </p>
        </div>
      ),
    },
  ];

  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentPage((prev) => Math.min(prev + 1, pages.length - 1));
  };

  const handleBack = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  const onSubmit = async (data: OnboardingFormValues) => {
    try {
      await updateUser({
        id: user.id,
        displayName: data.displayName.trim() || undefined,
        onboardingComplete: true,
      });

      onClose();
    } catch (error) {
      console.error("Failed to update user:", error);
    }
  };

  const handleClose = async () => {
    try {
      const formData = getValues();

      await updateUser({
        id: user.id,
        displayName: formData.displayName || user.displayName,
        onboardingComplete: true,
      });

      onClose();
    } catch (error) {
      console.error("Failed to handle modal close:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title="" className="md:w-[550px] z-[999999]">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full">
          <div className="flex flex-col items-center mb-4">
            <h2 className="text-2xl font-bold">{pages[currentPage].title}</h2>
            <p className="text-gray-600 mt-2">{pages[currentPage].description}</p>
          </div>

          {submitError && <div className="text-error text-center mb-4">{submitError}</div>}

          <div className="flex-grow my-4 flex items-center justify-center mx-4">{pages[currentPage].content}</div>

          <div className="flex items-center justify-between mt-6">
            <span className="text-gray-500">{`${currentPage + 1} of ${pages.length}`}</span>
            <div className="flex items-center gap-2">
              {currentPage > 0 && (
                <button type="button" className="btn btn-secondary" onClick={handleBack}>
                  Back
                </button>
              )}
              {currentPage < pages.length - 1 ? (
                <button type="button" className="btn btn-primary" onClick={handleNext}>
                  Continue
                </button>
              ) : (
                <button type="submit" className="btn btn-success text-white">
                  Complete
                </button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default OnboardingModal;
