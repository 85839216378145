import { lazy, Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OnboardingModal from "../components/ui/dialog/OnboardingModal";
import Loading from "../components/ui/Loading";
import { useActiveHomeFeedContext } from "../hooks/activeHomeFeed";
import { useAuth } from "../hooks/auth";
import { useGetUser } from "../hooks/user";

const MarketingSignUp = lazy(() => import("./marketing/MarketingSignUp"));
const Discovery = lazy(() => import("./Discovery"));
const Following = lazy(() => import("./Following"));

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, getUserId } = useAuth();
  const { activeFeed, setActiveFeed } = useActiveHomeFeedContext();

  const { data: user, isLoading: isUserLoading } = useGetUser(getUserId() as string);

  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const handleCloseOnboarding = () => {
    setIsOnboardingOpen(false);
    navigate({ pathname: location.pathname, hash: "" });
  };

  useEffect(() => {
    if (location.hash === "#onboarding" && user && !isUserLoading && !user?.onboardingComplete) {
      setIsOnboardingOpen(true);
    }
  }, [location.hash, user?.onboardingComplete, user, isUserLoading]);

  if (isLoading) {
    return <Loading className="h-full" />;
  }

  if (!isAuthenticated) {
    return (
      <Suspense fallback={<Loading className="h-full" />}>
        <MarketingSignUp />
      </Suspense>
    );
  }

  if (isAuthenticated && isUserLoading) {
    return <Loading className="h-full" />;
  }

  return (
    <div className="relative bg-transparent w-full h-full rounded-3xl">
      <div className="absolute top-2 right-0 left-0 z-10 bg-base-100 bg-opacity-80 p-2 mx-auto w-48 h-10 rounded-full shadow-lg">
        <div className="flex justify-center text-center space-x-6 sm:space-x-8 text-sm">
          <button
            onClick={() => setActiveFeed("discover")}
            className={`relative text-secondary transition hover:text-primary ${
              activeFeed === "discover" ? "font-semibold text-primary border-primary border-b-2" : "text-gray-400"
            }`}
          >
            Discover
          </button>
          <button
            onClick={() => setActiveFeed("following")}
            className={`relative transition hover:text-primary ${
              activeFeed === "following" ? "font-semibold text-primary border-primary border-b-2" : "text-gray-400"
            }`}
          >
            Following
          </button>
        </div>
      </div>

      <Suspense fallback={<Loading className="h-screen" />}>
        {activeFeed === "discover" && <Discovery />}
        {activeFeed === "following" && <Following />}
      </Suspense>

      {isOnboardingOpen && user && (
        <OnboardingModal isOpen={isOnboardingOpen} onClose={handleCloseOnboarding} user={user} />
      )}
    </div>
  );
}
