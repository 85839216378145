// Pages to exclude sidebar
export const dontShowSidebar = [
  "/sign-in",
  "/sign-up",
  "/forgot-password",
  "/reset-password",
  "/confirm-signup",
  "/terms-of-service",
  "/privacy-policy",
  "/cookie-policy",
];
