import { z } from "zod";
import { contentItemUri } from "../shared.js";

export const backfillContentMessageSchema = z.object({
  followerUserId: z.string(),
  followedUserId: z.string(),
});
export type BackfillContentMessage = z.infer<typeof backfillContentMessageSchema>;

export const fanOutContentMessageSchema = z.object({
  contentItemUri: contentItemUri,
});
export type FanOutContentMessage = z.infer<typeof fanOutContentMessageSchema>;

export const removeContentMessageSchema = z.object({
  followerUserId: z.string(),
  unfollowedUserId: z.string(),
});
export type RemoveContentMessage = z.infer<typeof removeContentMessageSchema>;

export const createSessionEventsMessageSchema = z.object({
  contentItemUri: contentItemUri,
});
export type CreateSessionEventsMessage = z.infer<typeof createSessionEventsMessageSchema>;
