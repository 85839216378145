import { SessionEvent } from "@yoga-app/types";

/**
 * Check if a sessionEvent can be purchased, returning an error message if not otherwise null.
 */
export const checkIfSessionEventCanBePurchased = (sessionEvent?: SessionEvent): string | null => {
  if (!sessionEvent) {
    return "No session selected.";
  }

  if (sessionEvent.maxParticipantCount && sessionEvent.participantCount >= sessionEvent.maxParticipantCount) {
    return "This session is full and cannot accept more participants.";
  }

  if (new Date(sessionEvent.endDateTime) < new Date()) {
    return "This session has already ended.";
  }

  return null;
};
