import { useAuth, useAuthPrompt } from "@/hooks/auth";
import { useGetPayment } from "@/src/hooks/payment";
import { useGetSessionEventUsersBySessionEventId } from "@/src/hooks/session";
import { useGetUser } from "@/src/hooks/user";
import { formatDateRange, formatDuration } from "@/src/utils/date";
import { moneyFormatter } from "@/src/utils/formatters";
import { getContentPageLink } from "@/src/utils/navigation";
import { checkIfSessionEventCanBePurchased } from "@/src/utils/payment";
import { Session, SessionEvent, SessionEventUser } from "@yoga-app/types";
import { useState } from "react";
import ParticipantsModal from "../content/ParticipantsModal";
import ProfilePicture from "../profile/ProfilePicture";
import ParticipantCountBadge from "../ui/badge/ParticipantCountBadge";
import Icon from "../ui/Icon";
import SessionEventSelector from "./SessionEventSelector";

interface SessionDetailProps {
  session: Session;
  isOwner: boolean;
  selectedSessionEvent?: SessionEvent;
  onSessionEventChange: (sessionEvent: SessionEvent) => void;
  hideTicketLink?: boolean;
}

const SessionDetail = ({
  session,
  isOwner,
  selectedSessionEvent,
  onSessionEventChange,
  hideTicketLink,
}: SessionDetailProps) => {
  const { isAuthenticated } = useAuth();
  const { openAuthPromptModal } = useAuthPrompt();
  const formattedPrice = moneyFormatter.format(session.price / 100);
  const { data: paymentData } = useGetPayment(selectedSessionEvent?.id || "");

  const isPaid = paymentData?.paymentRecordFound ? paymentData.payment?.status === "COMPLETED" : false;

  return (
    <div className="w-full h-full rounded-xl my-1 mb-2 flex flex-col justify-between">
      <div className="flex flex-row justify-between items-start p-3 pb-1 space-x-4">
        {/* Left Section */}
        <div className="flex-1 flex flex-col sm:ml-1">
          <div className="flex flex-col gap-2">
            <h3 className="text-xl sm:text-2xl font-semibold text-primary">{session?.title}</h3>

            <div className="flex flex-col sm:flex-row flex-wrap gap-2 text-sm sm:text-base">
              <div className="flex items-center gap-1">
                <Icon
                  name={session.locationType === "online" ? "computer" : "location_on"}
                  color="#212529"
                  size={18}
                  className="mb-1"
                />
                <span className="text-secondary">{session.locationType === "online" ? "Online" : "In-Person"}</span>
              </div>

              {session.locationType === "in-person" && (
                <div className="flex items-center gap-1">
                  <Icon name="location_on" color="#212529" size={18} className="mb-1" />
                  <div className="text-secondary truncate">{[session.city].filter(Boolean).join(", ")}</div>
                </div>
              )}

              {/* Duration */}
              <div className="flex items-center gap-1">
                <Icon name="schedule" color="#212529" size={18} className="mb-1" />
                <span className="text-secondary">{formatDuration(session.duration)}</span>
              </div>

              {/* Date time */}
              {selectedSessionEvent && (
                <div className="flex items-center gap-1">
                  <Icon name="event" color="#212529" size={18} className="mb-1" />
                  <span className="text-secondary">
                    {formatDateRange(selectedSessionEvent?.startDateTime, selectedSessionEvent?.endDateTime, {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="h-full flex flex-col justify-between items-end relative">
          {/* Calendar Card */}
          <div className="relative flex-shrink-0">
            <SessionEventSelector
              sessionId={session.id}
              userId={session.userId}
              selectedSessionEvent={selectedSessionEvent}
              onSessionEventChange={onSessionEventChange}
            />
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="flex flex-row w-full justify-between p-3 mt-2">
        <div className="flex justify-start items-start min-w-20">
          <ParticipantInfo session={session} selectedSessionEvent={selectedSessionEvent} isOwner={isOwner} />
        </div>
        <div className="flex justify-end items-end">
          {isOwner &&
            !hideTicketLink &&
            (() => {
              const errorMessage = checkIfSessionEventCanBePurchased(selectedSessionEvent);

              return errorMessage ? (
                <button className="link link-primary no-underline" disabled title={errorMessage}>
                  Not Available - {formattedPrice}
                </button>
              ) : (
                <button className="link link-info no-underline flex items-center" disabled>
                  Tickets Available - {formattedPrice}
                  <Icon name="chevron_right" color="#4361ee" size={18} className="ml-1 sm:mb-0.5" />
                </button>
              );
            })()}
          {!isOwner &&
            !hideTicketLink &&
            (() => {
              if (!isAuthenticated) {
                return (
                  <button
                    className="link link-info no-underline flex items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      openAuthPromptModal("buy_tickets");
                    }}
                  >
                    Buy Tickets - {formattedPrice}
                    <Icon name="chevron_right" color="#4361ee" size={18} className="ml-1 sm:mb-0.5" />
                  </button>
                );
              }

              const errorMessage = checkIfSessionEventCanBePurchased(selectedSessionEvent);
              const checkoutLink =
                selectedSessionEvent &&
                `${getContentPageLink(session.username, session.id, session.type)}?sessionEventId=${selectedSessionEvent.id}#checkout`;

              if (isPaid) {
                return (
                  <a href={checkoutLink} className="link link-info no-underline flex items-center">
                    View Ticket
                    <Icon name="chevron_right" color="#4361ee" size={18} className="ml-1 sm:mb-0.5" />
                  </a>
                );
              }

              return errorMessage ? (
                <button className="link link-primary no-underline" disabled title={errorMessage}>
                  Not Available - {formattedPrice}
                </button>
              ) : (
                <a href={checkoutLink} className="link link-info no-underline flex items-center">
                  Buy Tickets - {formattedPrice}
                  <Icon name="chevron_right" color="#4361ee" size={18} className="ml-1 sm:mb-0.5" />
                </a>
              );
            })()}
        </div>
      </div>
    </div>
  );
};

export default SessionDetail;

interface ParticipantInfoProps {
  session: Session;
  isOwner: boolean;
  selectedSessionEvent?: SessionEvent;
}

const ParticipantInfo = ({ session, selectedSessionEvent, isOwner }: ParticipantInfoProps) => {
  const [participantsModalOpen, setParticipantsModalOpen] = useState(false);

  const { data } = useGetSessionEventUsersBySessionEventId(
    selectedSessionEvent?.id ?? "",
    session.userId,
    {
      limit: 3,
    },
    {
      enabled: (selectedSessionEvent?.participantCount ?? 0) > 0,
    },
  );

  const participants = data?.pages.flatMap((page) => page.items) || [];

  //Limit the number of participants to show on the card
  const participantsLimited = participants.slice(0, 3);
  //Work out how many additional participants there are
  const additionalParticipants = participants.length - participantsLimited.length;

  return (
    <div className="flex items-center">
      <div className="flex flex-col">
        {/* Participant Avatars */}
        {(selectedSessionEvent?.participantCount ?? 0) > 0 && participants.length && (
          <div className="flex -space-x-2 pb-2 pl-2" onClick={() => setParticipantsModalOpen(true)}>
            {participantsLimited.map((participant) => (
              <ParticipantAvatar key={participant.userId} participant={participant} />
            ))}
            {additionalParticipants > 0 && (
              <div className="w-10 h-10 flex items-center text-accent justify-center bg-secondary text-sm font-semibold rounded-full select-none cursor-pointer">
                +{additionalParticipants}
              </div>
            )}
          </div>
        )}

        {/* Participant Count Badge */}
        {(isOwner || (selectedSessionEvent?.participantCount ?? 0) > 0) && (
          <ParticipantCountBadge
            participantCount={selectedSessionEvent?.participantCount ?? 0}
            maxParticipantCount={selectedSessionEvent?.maxParticipantCount}
            className="bg-transparent outline-none border-none cursor-pointer"
            onClick={() => setParticipantsModalOpen(true)}
          />
        )}
      </div>

      <ParticipantsModal
        isOpen={participantsModalOpen}
        onClose={() => setParticipantsModalOpen(false)}
        selectedSessionEvent={selectedSessionEvent}
      />
    </div>
  );
};

const ParticipantAvatar = ({ participant }: { participant: SessionEventUser }) => {
  const { data: user } = useGetUser(participant.userId);
  return (
    <ProfilePicture
      key={user?.id}
      src={user?.profilePic?.[150]}
      user={user}
      styles="w-10 h-10 cursor-pointer"
      avatarSize="text-sm"
    />
  );
};
