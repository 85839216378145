import { Package, Post, Session, User } from "@yoga-app/types";
import ContentContent from "../card/ContentContent";

interface PreviewCardProps {
  // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
  content: Post | Session | Package;
}

const PreviewCard = ({ content }: PreviewCardProps) => {
  return (
    <div className="bg-neutral rounded-lg shadow mb-1 max-w-4xl mx-auto min-w-2xl w-full">
      <EmptyHeading />

      <ContentContent
        content={content}
        user={{ id: content.userId, username: content.username, profilePic: { "150": "" } } as unknown as User}
        selectedSessionEvent={undefined}
        onSessionEventChange={() => {}}
      />

      <EmptyActionBar />
    </div>
  );
};

export default PreviewCard;

const EmptyHeading = () => {
  return (
    <div className="flex items-center justify-between p-4 pb-2 border-b border-gray-200">
      <div className="w-24 h-6 bg-gray-300 rounded"></div>
      <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
    </div>
  );
};

const EmptyActionBar = () => {
  return (
    <div className="flex justify-between items-center p-4 border-t border-gray-200">
      <div className="flex items-center space-x-4 ml-auto">
        <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
        <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
      </div>
    </div>
  );
};
