import { useMutation } from "@tanstack/react-query";
import { PageOptions } from "@yoga-app/db-utils";
import { Session } from "@yoga-app/types";
import { useEffect, useMemo, useState } from "react";
import config from "../config";
import { request } from "../utils/api";
import { isValidDate } from "../utils/date";
import { trpc } from "../utils/trpc";
import { useAuth } from "./auth";

export const useCreateSessionMutation = () => {
  const { getAccessToken } = useAuth();
  const utils = trpc.useUtils();

  return useMutation(
    async (session: Session) => {
      const response = await request<{ sessionId: string }>(`${config.api.url}/session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(session),
      });

      if (!response) {
        throw new Error("Failed to create session");
      }

      return response;
    },
    {
      onSuccess: async (_, variables) => {
        const { userId } = variables;
        await utils.content.getContentByUser.invalidate({ userId });
        await utils.session.querySessionEventsBySessionId.invalidate({ sessionId: variables.id }, { exact: false });
      },
      onError: (error) => {
        console.error("Error creating session:", error);
      },
    },
  );
};

export const useGetSession = (username: string, sessionId: string) => {
  return trpc.session.getSession.useQuery(
    {
      username: username,
      sessionId: sessionId,
    },
    {
      enabled: !!username && !!sessionId,
    },
  );
};

export const useGetSessionEventUsersBySessionEventId = (
  sessionEventId: string,
  contentOwnerUserId: string,
  pageOptions: PageOptions,
  options?: { enabled?: boolean },
) => {
  return trpc.session.getSessionEventUsersBySessionEventId.useInfiniteQuery(
    {
      sessionEventId: sessionEventId,
      contentOwnerUserId: contentOwnerUserId,
      limit: pageOptions.limit,
    },
    {
      enabled: !!sessionEventId && !!contentOwnerUserId && (options?.enabled ?? true),
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
      cacheTime: 10 * 60 * 1000,
      refetchOnMount: true,
    },
  );
};

export const useQueryAllUserSessionEvents = (userId: string, startDate: string, endDate: string, enabled?: boolean) => {
  const query = trpc.session.queryUserSessionEvents.useInfiniteQuery(
    { userId, startDate, endDate },
    {
      enabled: enabled ?? !!(userId && startDate && endDate),
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
      cacheTime: 10 * 60 * 1000,
    },
  );

  const { data: queryData, hasNextPage, isLoading, isFetching, fetchNextPage, refetch } = query;
  const [isLoadingPages, setIsLoadingPages] = useState(isLoading);

  useEffect(() => {
    if (hasNextPage) {
      void fetchNextPage();
    } else {
      setIsLoadingPages(isLoading);
    }
  }, [queryData, hasNextPage, isLoading, fetchNextPage]);

  const data = useMemo(() => {
    return queryData ? queryData.pages.flatMap((page) => page.items) : [];
  }, [queryData]);

  return {
    data,
    isLoading: isLoadingPages,
    isFetching,
    refetch,
  };
};

export const useQuerySessionEventsBySessionId = (
  sessionId: string,
  fromDate: string,
  direction: "PAST" | "FUTURE",
  pageOptions?: PageOptions,
) => {
  const { isAuthenticated } = useAuth();
  return trpc.session.querySessionEventsBySessionId.useInfiniteQuery(
    {
      sessionId,
      fromDate,
      direction,
      limit: pageOptions?.limit,
    },
    {
      enabled: !!sessionId && isValidDate(fromDate) && isAuthenticated,
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
      cacheTime: 10 * 60 * 1000,
    },
  );
};

export const useGetSessionEvent = (userId: string, sessionEventId: string) => {
  return trpc.session.getSessionEvent.useQuery(
    {
      userId,
      sessionEventId,
    },
    {
      enabled: !!userId && !!sessionEventId,
      staleTime: 15 * 60 * 1000, // 15 minutes in milliseconds
      cacheTime: 15 * 60 * 1000,
    },
  );
};
