import { v4 } from "uuid";
import { z } from "zod";
import { contentTypeSchema, entitySchema } from "./shared.js";

/**
 * A comment is a response to content.
 */
export const commentSchema = z.object({
  /**
   * A unique identifier for the comment.
   */
  id: z.string().default(() => v4()),
  /**
   * The user id of the user who created the comment.
   */
  userId: z.string(),
  /**
   * The id of the content item being commented on.
   */
  contentId: z.string(),

  /**
   * The user id of the content owner.
   */
  contentOwnerUserId: z.string(),

  /**
   * The type of content item being commented on.
   */
  type: contentTypeSchema,
  /**
   * The text content of the comment.
   */
  text: z.string().max(280),

  /**
   * Entities such as images, videos
   */
  entities: entitySchema.array().optional(),

  /**
   * The id of the parent comment, if this comment is apart of a thread.
   */
  parentCommentId: z.string().optional(),

  /**
   * deleted flag
   */
  deleted: z.boolean().default(false),

  /**
   * Mentions. Tag users in a post, linking them to the post.
   * array of user ids
   */
  mentions: z.array(z.string()).optional(),

  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type Comment = z.infer<typeof commentSchema>;

export const commentTypeSchema = z.enum(["CONTENT", "THREAD"]);
export type CommentType = z.infer<typeof commentTypeSchema>;

export const commentCountSchema = z.object({
  /**
   * The unique identifier for the count.
   * For content-level counts, this would be the contentId.
   * For thread-level counts, this would be the parentCommentId.
   */
  parentId: z.string(),

  /**
   * The type of target for the count: 'CONTENT' for content-level comments or 'THREAD' for thread-level replies.
   */
  type: commentTypeSchema,
  /**
   * The ID of the root content (e.g., post) this count belongs to.
   * For content-level counts, this is the same as `parentId`.
   * For thread-level counts, this is the ID of the associated content.
   */
  contentId: z.string(),
  /**
   * Total number of comments or replies.
   */
  count: z.number().default(0),
  /**
   * Timestamp when the record was created.
   */
  createdAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
  /**
   * Timestamp when the record was last updated.
   */
  updatedAt: z
    .string()
    .datetime({ offset: true })
    .default(() => new Date().toISOString()),
});

export type CommentCount = z.infer<typeof commentCountSchema>;
