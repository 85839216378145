import { createContext, ReactNode, useEffect, useState } from "react";

type Feed = "discover" | "following";

interface ActiveHomeFeedContextProps {
  activeFeed: Feed;
  setActiveFeed: (feed: Feed) => void;
}

export const ActiveHomeFeedContext = createContext<ActiveHomeFeedContextProps | undefined>(undefined);

export const ActiveHomeFeedProvider = ({ children }: { children: ReactNode }) => {
  const [activeFeed, setActiveFeed] = useState<Feed>(() => {
    return (localStorage.getItem("activeFeed") as Feed) || "discover";
  });

  useEffect(() => {
    localStorage.setItem("activeFeed", activeFeed);
  }, [activeFeed]);

  return (
    <ActiveHomeFeedContext.Provider value={{ activeFeed, setActiveFeed }}>{children}</ActiveHomeFeedContext.Provider>
  );
};
