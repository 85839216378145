import { PageOptions } from "@yoga-app/db-utils";
import { trpc } from "../utils/trpc";
import { useAuth } from "./auth";

export const useGetNotifications = (userId: string, pageOptions: PageOptions) => {
  const { isAuthenticated } = useAuth();
  return trpc.notification.queryNotificationsByUser.useInfiniteQuery(
    {
      userId,
      limit: pageOptions.limit,
    },
    {
      enabled: !!userId && isAuthenticated,
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      staleTime: 300000,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
    },
  );
};

export const useUpdateNotificationStatus = () => {
  const utils = trpc.useUtils();
  return trpc.notification.updateNotificationStatus.useMutation({
    onSuccess: async (_, input) => {
      await utils.notification.queryNotificationsByUser.invalidate({ userId: input.userId }, { exact: false });
    },
  });
};
