const CookiePolicy = () => {
  return (
    <div className="container mx-auto max-w-4xl p-6">
      <h1 className="text-4xl font-bold mb-6 text-center">Cookie Policy</h1>

      <div className="space-y-6 text-lg text-gray-700">
        {/* Introduction */}
        <section>
          <h2 className="text-2xl font-semibold">1. Introduction</h2>
          <p>
            At bodhiga, we use cookies and similar technologies, such as local storage, to improve your experience.
            These tools help us provide a faster, smoother, and more secure platform. They are essential for running our
            services, including our website, applications, APIs, and email communications.
          </p>
          <p>Specifically, bodhiga uses these technologies to</p>
          <ul className="list-disc pl-8">
            <li>Keep you logged in to your account.</li>
            <li>Enable core features and functionality across bodhiga services.</li>
            <li>Save and respect your preferences.</li>
            <li>Personalize the content and recommendations you see.</li>
            <li>Protect you from spam, abuse, and other security risks.</li>
            <li>Support subscription features and deliver exclusive content.</li>
            <li>Analyze how you interact with our services to enhance your experience.</li>
            <li>Measure the success of our advertising and marketing efforts.</li>
            <li>Monitor service performance and identify bugs or issues.</li>
            <li>Collect essential data to support our business operations.</li>
          </ul>
          <p>These technologies allow us to provide you with a seamless and personalized experience on bodhiga.</p>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* What Are Cookies and Local Storage? */}
        <section>
          <h2 className="text-2xl font-semibold">2. What Are Cookies and Local Storage?</h2>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">2.1 Cookies</h3>
            <p>
              Cookies are small files stored on your computer or device as you browse websites or use web-enabled apps.
              At bodhiga, we use cookies to power our services, understand how people interact with them, and find ways
              to improve their functionality. Cookies help us provide features like staying logged in, remembering your
              preferences, and more.
            </p>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">2.2 Local Storage</h3>
            <p>
              Local storage allows us to save data directly on your computer or mobile device. We use this data to
              enable smooth navigation, remember video player settings, and tailor your experience based on past
              interactions.
            </p>
          </div>
          <p>These tools collectively enable us to deliver a seamless and customized experience on bodhiga.</p>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* Why Does bodhiga Use These Technologies? */}
        <section>
          <h2 className="text-2xl font-semibold">3. Why Does bodhiga Use These Technologies?</h2>
          <p>
            Cookies and local storage are essential for running and improving our platform, supporting our business, and
            providing you with a better experience. These tools are used across various categories, including:
          </p>
          <ul className="list-disc pl-8">
            <li>Authentication and security</li>
            <li>Functionality</li>
            <li>User preferences</li>
            <li>Analytics</li>
            <li>Research and development</li>
            <li>Personalized content</li>
            <li>Advertising</li>
            <li>Marketing</li>
            <li>Personalization across devices</li>
          </ul>
          <p>Here’s how they help in each category:</p>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">3.1 Authentication and Security</h3>
            <p>
              These technologies verify your access to bodhiga and safeguard your account, ensuring only authorised
              users can log in and view content. Specifically, they:
            </p>
            <ul className="list-disc pl-8">
              <li>Keep you logged in to bodhiga.</li>
              <li>Protect your account and our services from unauthorized access.</li>
              <li>Detect and prevent spam, abuse, and violations of our rules.</li>
            </ul>
          </div>
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">3.2 Functionality</h3>
            <p>Cookies and local storage ensure a seamless user experience by:</p>
            <ul className="list-disc pl-8">
              <li>Delivering error messages when needed.</li>
              <li>Allowing you to switch between accounts.</li>
              <li>Synchronizing your activity across multiple browser tabs.</li>
            </ul>
          </div>

          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">3.3 User Preferences</h3>
            <p>
              These technologies remember your preferences and browser settings, so bodhiga can provide a consistent and
              tailored experience.
            </p>
          </div>

          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">3.4 Analytics</h3>
            <p>
              We use these tools to better understand how users interact with our platform and improve it over time. For
              example, they help us:
            </p>
            <ul className="list-disc pl-8">
              <li>Analyze user interactions with features like buttons, widgets, and links.</li>
              <li>Track how frequently you use our services and which content you engage with.</li>
              <li>Count how many people view posts, embeds, or other elements.</li>
            </ul>
          </div>

          {/* Research and Development */}
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">3.5 Research and Development</h3>
            <p>Cookies and local storage help us:</p>
            <ul className="list-disc pl-8">
              <li>Gather performance data to identify issues and areas for improvement.</li>
              <li>Test updates and build new features for bodhiga.</li>
            </ul>
          </div>
          {/* Personalised content */}
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">3.6 Personalized Content</h3>
            <p>These tools allow us to customise your experience by:</p>
            <ul className="list-disc pl-8">
              <li>Suggesting relevant trends, stories, and connections based on your activity.</li>
              <li>Showing you content that matches your interests.</li>
              <li>Using local storage to display new content on your feed.</li>
            </ul>
          </div>

          {/* Marketing */}
          <div className="mt-4 pl-4 border-l-4 border-gray-300">
            <h3 className="text-xl font-semibold">3.7 Marketing</h3>
            <p>
              We use these technologies to deliver and measure the success of our email and web marketing campaigns.
            </p>
          </div>
        </section>

        <div className="divider divider-horizontal"></div>

        {/* Where Are These Technologies Used? */}
        <section>
          <h2 className="text-2xl font-semibold">4. Where Are These Technologies Used?</h2>
          <p>
            At bodhiga, we (and third parties) use cookies and local storage across our services, including our website,
            applications, and other tools. Additionally, third parties may use these technologies when you:
          </p>
          <ul className="list-disc pl-8">
            <li>Click on links from bodhiga’s services.</li>
            <li>View or interact with third-party content within bodhiga.</li>
          </ul>
          <p>
            These integrations help us provide a seamless experience across platforms and ensure that you see relevant
            and personalised content.
          </p>
        </section>
      </div>
    </div>
  );
};

export default CookiePolicy;
