import { useGetSignedUrls } from "@/hooks/media";
import { getContentPageLink } from "@/src/utils/navigation";
import { getContentIcon } from "@/utils/icon";
import { PackageItemWithDetail, isImageEntity } from "@yoga-app/types";
import { Link } from "react-router-dom";
import CustomImage from "../../ui/CustomImage";
import Icon from "../../ui/Icon";

interface PackageItemCardProps {
  item: PackageItemWithDetail;
}

const PackageItemCard = ({ item }: PackageItemCardProps) => {
  const imageEntity = item.item.entities?.find(isImageEntity);
  const { data: signedUrls = [], isLoading } = useGetSignedUrls(imageEntity ? [imageEntity.url?.[800]] : []);
  const imageUrl = imageEntity ? signedUrls.find((url) => url.key === imageEntity.url?.[800])?.signedUrl : null;

  const contentPageLink = getContentPageLink(item.item.username, item.item.id, item.item.type);

  return (
    <Link to={contentPageLink} className="flex flex-col items-center no-underline">
      <div className="w-[150px] h-[125px] bg-base-100 rounded-xl shadow-sm mb-2">
        {imageEntity && isLoading ? (
          <div className="w-full h-full skeleton"></div>
        ) : imageUrl ? (
          <div className="w-full h-full aspect-[1/1]">
            <CustomImage
              src={imageUrl}
              alt={item.item.type}
              className="w-full h-full object-cover rounded-lg shadow-sm"
            />
          </div>
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-300 rounded-lg aspect-[1/1]">
            <Icon name={getContentIcon(item.item.type)} size={38} color="#9ca3af" />
          </div>
        )}
      </div>
      {/* Dot below the card */}
      <div className="w-1.5 h-1.5 rounded-full bg-gray-400 mt-1"></div>
    </Link>
  );
};

export default PackageItemCard;
