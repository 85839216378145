import { AuthContext, AuthContextType } from "@/contexts/AuthContext";
import { useContext } from "react";
import { AuthPromptContext } from "../contexts/AuthPromptContext";

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const useAuthPrompt = () => {
  const context = useContext(AuthPromptContext);
  if (context === undefined) {
    throw new Error("useAuthPrompt must be used within an AuthPromptProvider");
  }
  return context;
};
