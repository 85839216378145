import { useContext } from "react";
import { ActiveHomeFeedContext } from "../contexts/ActiveHomeFeedContext";

export const useActiveHomeFeedContext = () => {
  const context = useContext(ActiveHomeFeedContext);
  if (!context) {
    throw new Error("useActiveHomeFeedContext must be used within a ActiveHomeFeedProvider");
  }
  return context;
};
